import React, { CSSProperties } from 'react'
import { getGatsbyImageData } from 'gatsby-source-sanity'
import { GatsbyImage, getSrc, getSrcSet } from 'gatsby-plugin-image'

// const sanityConfig = { projectId: "s2j9sc95", dataset: "production" };
import sanityConfig from '../../config/sanity'

type Props = {
  image: {
    asset: Queries.SanityImageAsset | any
  }
  caption?: any
  objectFit?: string
  thumbnail?: boolean
  height?: number
  _onLoad?: Function
}
const SanityImage = ({
  image,
  caption,
  objectFit = 'contain',
  thumbnail = false,
  height = 2000,
  _onLoad, // showPlaceholder = true,
}: Props) => {
  // console.log(_onLoad)
  const { asset } = image
  // const { metadata } = asset
  const metadata = asset?.metadata
  const aspectRatio = metadata?.dimensions
    ? metadata?.dimensions?.aspectRatio
    : '1'
  // let options = {}
  let options = {
    width: 2000,
    height: 2000 / aspectRatio,
    fit: 'FILLMAX',
    // placeholder: 'blurred',
    placeholder: 'dominantColor',
    formats: ['auto', 'webp'],
  }
  if (thumbnail) {
    options.height = height
    options.width = height * aspectRatio
  }
  // console.log(sanityConfig.sanity)
  let dataset = asset.url.indexOf('preprod') > -1 ? 'preprod' : 'production'
  const config = {
    projectId: sanityConfig.sanity.projectId,
    dataset: dataset,
  }
  // console.log(asset)
  const imageData = getGatsbyImageData(asset, options, config)
  // const src = getSrc(asset)
  // const srcSet = getSrcSet(asset)
  // console.log(src)
  return (
    <div className="pointer-events-none">
      {imageData && (
        <GatsbyImage
          image={imageData}
          alt={caption ? caption : ''}
          // objectFit={CSSProperties[objectFit]}
          imgStyle={{
            aspectRatio: `${options.width} / ${options.height}`,
            objectFit: objectFit,
          }}
          onLoad={_onLoad}
          loading="lazy"
          data-filename={asset.filename}
        />
      )}
      {/* <img src={src} alt={caption ? caption : ''} /> */}
      {/* <img
        src={asset.url}
        alt=""
        style={{
          maxWidth: '100%',
        }}
      /> */}
    </div>
  )
}

export default SanityImage
