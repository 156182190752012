import React, { useEffect, useState } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { _localizeField, _localizeText, _linkResolver } from '../core/utils'
import SanityTexte from './SanityTexte'
// import A from '../images/a.inline.svg'
// import B from '../images/b.inline.svg'
import LocaleSwitcherMini from './ui/LocaleSwitcherMini'
import LabelImages from './ui/media/LabelImages'
import ButtonHome from './ui/buttons/ButtonHome'
import SanityImage from './SanityImage'
import AeaiCredits from './ui/AeaiCredits'
import clsx from 'clsx'
// import ExternalLink from './ui/buttons/ExternalLink'
// import AeaiCredits from './ui/AeaiCredits'
// import ExternalLink from './ui/buttons/ExternalLink'

const query = graphql`
  query {
    sanityFooter {
      media {
        label {
          ...localeString
        }
        images: _rawImages(resolveReferences: { maxDepth: 10 })
      }
      contacts {
        ...localeBlockContent
      }
      partners {
        ...localeBlockContent
      }

      partnersLogo {
        label {
          ...localeString
        }
        images: _rawImages(resolveReferences: { maxDepth: 10 })
      }
      navMenu {
        ... on SanityInternalLink {
          _key
          _type

          label {
            ...localeString
          }
          link {
            ... on SanityBasic {
              _type
              landingPage
              slug {
                current
              }
            }
            ... on SanitySerie {
              _type
              slug {
                current
              }
            }
          }
        }
        ... on SanityExternalLink {
          _key
          _type
          label {
            ...localeString
          }
          url: link
        }
        ... on SanityDownload {
          _key
          _type
          label {
            ...localeString
          }
          file {
            asset {
              url
            }
          }
          # url: link
        }
      }
      links {
        ... on SanityExternalLink {
          link
          _type
          label {
            de
          }
        }
      }
      credits {
        ...localeBlockContent
      }
      textImage {
        text {
          ...localeBlockContent
        }
        image: _rawImage(resolveReferences: { maxDepth: 10 })
      }
    }
  }
`

const Footer = () => {
  const data: Queries.SanityFooter = useStaticQuery(query).sanityFooter
  const { navMenu, textImage, credits } = data
  const [creditsActive, setCreditsActive] = useState<boolean>(false)
  // const {
  //   sanityFooter: { media, contacts, partnersLogo, navMenu, links, textImage },
  //   // sanityHeader: { navMenu },
  // } = useStaticQuery(query)

  // const nav = [...navMenu, ...links]

  useEffect(() => {
    if (creditsActive) {
      const credits = document.querySelector('.aeai-naasner-credits')
      if (credits) {
        credits.scrollIntoView({
          behavior: 'smooth',
        })
      }
    }
  }, [creditsActive])

  return (
    <footer className="px-1">
      <div className="content  pt-1_5 ">
        {textImage &&
          textImage.length > 0 &&
          textImage.map((item, i) => (
            <div className="text-image divider-before" key={i}>
              <div className="py-1_5">
                <div className="row">
                  <div className="col-md-4 col-xs-8">
                    <div className="mb-1_5">
                      <div className="ui-max-width">
                        <SanityTexte input={_localizeField(item.text)} />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-xs-8">
                    <div className="mb-1_5">
                      <div className="ui-max-width">
                        <SanityImage image={item.image} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
      <nav className="links divider-before">
        <div className="py-1_5">
          <h5 className="label mb-1">{_localizeText('links')}</h5>
          <ul className="flex items-center flex-wrap gap-05">
            <li className="btn-home">
              <ButtonHome />
            </li>
            {navMenu &&
              navMenu.length &&
              navMenu.map((li, i) => (
                <li key={i} className="">
                  {li._type === 'internalLink' && (
                    <Link
                      to={_linkResolver(li.link)}
                      className="btn btn--big md:btn--regular block"
                    >
                      {_localizeField(li.label)}
                    </Link>
                  )}
                  {li._type === 'externalLink' && (
                    <a
                      href={li.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn btn--big md:btn--regular block"
                    >
                      {_localizeField(li.label)}
                    </a>
                  )}
                  {li._type === 'download' && (
                    <a
                      href={li.file.asset.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn btn--big md:btn--regular block"
                    >
                      {_localizeField(li.label)}
                    </a>
                  )}
                </li>
              ))}

            <li>
              <button
                className={clsx(
                  'btn btn--big md:btn--regular',
                  creditsActive && 'is-active'
                )}
                onClick={() => setCreditsActive(!creditsActive)}
              >
                Credits
              </button>
            </li>
          </ul>
        </div>
        {creditsActive && (
          <div className="py-1_5">
            <AeaiCredits input={credits} />
          </div>
        )}
      </nav>
    </footer>
  )
}

export default Footer
