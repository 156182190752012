import React, { useEffect } from 'react'
import PortableText from '@sanity/block-content-to-react'
import MediaCard from './ui/MediaCard'
import { Link } from 'gatsby'
import { _linkResolver, _string_to_slug } from '../core/utils'
// import Figure from './ui/Figure'
// import CardMap from './ui/cards/CardMap'
// import ButtonImage from './ui/buttons/ButtonImage'
// import PeopleGroup from './PeopleGroup'
// import { _localizeField } from '../core/utils'

interface Props {
  input: any
  active?: boolean
}

const SanityTexte = ({ input, active = false }: Props) => {
  // console.log(input)
  const serializers = {
    types: {
      block(props) {
        // console.log(props.node.style)
        // switch (props.node.style) {
        //   case 'h2':
        //     return <h2>{props.children}</h2>
        //   case 'text-lg':
        //     return <p className="text-lg">{props.children}</p>
        //   case 'text-sm':
        //     return <p className="text-sm">{props.children}</p>
        //   default:
        //     return <p>{props.children}</p>
        // }
        switch (props.node.style) {
          case 'h2':
            // console.log(props.children)
            return (
              <div
                className="container-ui-max-width paragraph py-1_5"
                id={
                  props.children &&
                  typeof props.children === 'object' &&
                  typeof props.children[0] === 'string'
                    ? _string_to_slug(props.children[0])
                    : ''
                }
              >
                {/* <pre>{JSON.stringify(props, null, 2)}</pre> */}
                <h2 className="text-lg font-medium">{props.children}</h2>
              </div>
            )
          case 'h3':
            return (
              <div className="container-ui-max-width paragraph py-1_5">
                <h3 className="text-md font-medium">{props.children}</h3>
              </div>
            )
          case 'h4':
            return (
              <div className="container-ui-max-width paragraph py-1_5">
                <h4 className="font-medium">{props.children}</h4>
              </div>
            )
          case 'text-lg':
            return <p className="text-lg py-1_5">{props.children}</p>
          case 'text-sm':
            return (
              <div className="container-ui-max-width paragraph py-1_5">
                <p className="text-sm ">{props.children}</p>
              </div>
            )
          case 'big-w-full':
            return (
              <div className="big-w-full paragraph py-1_5">
                <p className="text-lg md:text-xl">{props.children}</p>
              </div>
            )

          default:
            return (
              <div className="container-ui-max-width paragraph pb-1_5">
                <p>{props.children}</p>
              </div>
            )
        }
      },
      mediaCard: ({ node }) => {
        return <MediaCard input={node} active={active} />
      },
      // figure: ({ node }) => (
      //   <div className="mb-2">
      //     <Figure input={node} />
      //   </div>
      // ),
      // cardMap: ({ node }) => (
      //   <div className="mb-2">
      //     <div className="container">
      //       <CardMap input={node} />
      //     </div>
      //   </div>
      // ),
      // // location: ({ node }) => <pre>{JSON.stringify(node)}</pre>,
      // location: ({ node }) => (
      //   <div className="mb-2">
      //     <div className="container">
      //       <ButtonImage label={_localizeField(node.title)} />
      //     </div>
      //   </div>
      // ),
      // peopleGroup: ({ node }) => (
      //   <div className="mb-2">
      //     <div className="container">
      //       <PeopleGroup
      //         label={_localizeField(node.label)}
      //         people={node.people}
      //       />
      //       {/* <pre>{JSON.stringify(node, false, 4)}</pre> */}
      //     </div>
      //   </div>
      // ),
    },

    list: props => (
      <div className="container-ui-max-width py-1_5 component component--list">
        {props.type === 'number' && <ol>{props.children}</ol>}
        {props.type === 'bullet' && <ul>{props.children}</ul>}
      </div>
    ),
    marks: {
      sup: ({ children }) => <sup>{children}</sup>,
      internalLink: ({ mark, children }) => {
        // console.log(children, mark);
        // console.log(mark)
        let href = _linkResolver(mark.reference)
        if (mark.anchor) href += mark.anchor

        return (
          <Link className="btn- btn--regular-" to={href}>
            {children}
          </Link>
        )
      },
      link: ({ mark, children }) => {
        const { href } = mark
        return (
          <a
            href={href}
            target="_blank"
            rel="noopener, noreferrer"
            className="btn btn--regular"
          >
            {children}
          </a>
        )
      },
    },
  }

  useEffect(() => {
    let timer: any
    var tiltElements = document.querySelectorAll('.face, .btn--figure')
    var tiltClasses = [
      'tilt-0',
      'tilt-0',
      'tilt-1',
      'tilt-2',
      'tilt-reverse-1',
      'tilt-reverse-2',
    ]
    function tilt() {
      tiltElements.forEach(tiltElement => {
        tiltElement.classList.remove('tilt-0')
        tiltElement.classList.remove('tilt-1')
        tiltElement.classList.remove('tilt-2')
        tiltElement.classList.remove('tilt-reverse-1')
        tiltElement.classList.remove('tilt-reverse-2')
        var idx = Math.floor(Math.random() * tiltClasses.length)
        tiltElement.classList.add(tiltClasses[idx])
      })
      timer = setTimeout(tilt, 5000)
    }
    tilt()

    return () => {
      clearTimeout(timer)
    }
  }, [])

  return (
    <div className="texte">
      <PortableText blocks={input} serializers={serializers} />
    </div>
  )
}

export default SanityTexte
