import React from 'react'
import styled, { keyframes } from 'styled-components'

const pulse = keyframes`
  from {
    opacity: 0;
    /* transform: translateY(-6px); */
  }

  to {
    opacity: 1;
    /* transform: translateY(0px); */
  }
`

const Loader = styled.div`
  display: inline-block;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--space-05);
  /* width: 80px;
  height: 80px; */
  /* &::after {
    content: ' ';
    display: block;
    border-radius: 50%;
    width: 0;
    height: 0;
    margin: 8px;
    box-sizing: border-box;
    border: 32px solid #fff;
    border-color: orange yellow orange yellow;
    -webkit-animation: lds-hourglass 1.2s infinite;
    animation: lds-hourglass 1.2s infinite;
  } */
  /* .loader-dot {
    width: var(--space-1);
    height: var(--space-1);
    flex-basis: var(--space-1);
    border-radius: 100%;
    background-color: black;
  } */
`
interface Props {
  delay: string
  opacity: string
}

const LoaderDot = styled.div<Props>`
  width: var(--space-1);
  height: var(--space-1);
  flex-basis: var(--space-1);
  border-radius: 100%;
  background-color: black;
  opacity: ${p => p.opacity};
  animation: ${pulse} 500ms ease-out infinite;
  animation-delay: ${p => p.delay};
  animation-fill-mode: forwards;
  animation-direction: alternate-reverse;
`

const LoaderOverlay = () => {
  return (
    <div className="loader-overlay fixed inset-0 bg-white- z-50 pointer-events-none">
      <div className="flex-center h-full">
        <Loader className="lds-hourglass ">
          {/* <div className="loader-dot  opacity-10 delay-0 "></div>
          <div className="loader-dot  opacity-80 delay-100"></div>
          <div className="loader-dot  opacity-100 delay-200"></div> */}
          <LoaderDot opacity="0.1" delay="0ms" />
          <LoaderDot opacity="0.8" delay="200ms" />
          <LoaderDot opacity="1" delay="400ms" />
        </Loader>
      </div>
    </div>
  )
}

export default LoaderOverlay
