import React, { useEffect, createContext, useContext } from 'react'
import Header from './Header'
import Footer from './Footer'
import PageTransition from './ui/PageTransition'
import ModalImage from './ui/ModalImage'
import { subscribe, unsubscribe } from 'pubsub-js'
import LoaderOverlay from './ui/LoaderOverlay'
import { useState } from 'react'
import { Slice } from 'gatsby'
// import useFetch from '../hooks/useFetch'
// import SearchSanity from './ui/SearchSanity'

const WrapperContext = createContext({})

const Layout = ({ children, pageContext, location }) => {
  const [messageList, setMessageList] = useState([])
  const [headlinePayload, setHeadlinePayload] = useState({})
  const { template } = pageContext
  // console.log({ messageList })

  useEffect(() => {
    // console.log(location.pathname)
    _onRouteUpdate()
  }, [location])

  useEffect(() => {
    // _onRouteUpdate()
    _format()
    setTimeout(() => {
      _format()
    }, 150)
    window.addEventListener('resize', _format)

    const tokenPreRoute = subscribe('PRE_ROUTE_UPDATE', () => {
      console.log('PRE_ROUTE_UPDATE')
      document.body.classList.add('is-loading')
    })
    const tokenRoute = subscribe('POST_ROUTE_UPDATE', () => {
      console.log('POST_ROUTE_UPDATE')
      document.body.classList.remove('is-loading')
    })

    const tokenLoading = subscribe('IS_LOADING', (e, is) => {
      // console.log({ is })
      if (is) {
        document.body.classList.add('is-loading')
      } else {
        document.body.classList.remove('is-loading')
      }
    })

    const tokenNoScroll = subscribe('IS_MODAL', (e, is) => {
      if (is) {
        document.documentElement.classList.add('no-scroll')
      } else {
        document.documentElement.classList.remove('no-scroll')
      }
    })
    return () => {
      window.removeEventListener('resize', _format)
      unsubscribe(tokenLoading)
      unsubscribe(tokenPreRoute)
      unsubscribe(tokenRoute)
      unsubscribe(tokenNoScroll)
    }
  }, [])

  const _onRouteUpdate = () => {
    _format()
    if (template) document.body.dataset.template = template
    document.body.classList.remove('is-loading')
    document.documentElement.classList.remove('no-scroll')
    document.body.classList.toggle('is-home', location.pathname === '/')

    const mediaSection = document.querySelector('.media-section-wrapper')

    if (mediaSection) {
      document.body.classList.add('has-cover')
    } else {
      document.body.classList.remove('has-cover')
    }
  }

  const _format = () => {
    document.documentElement.style.setProperty(
      '--app-height',
      window.innerHeight + 'px'
    )
    const coverImage = document.querySelector('.cover-image')
    if (coverImage) {
      const coverImageBounding = coverImage.getBoundingClientRect()
      document.documentElement.style.setProperty(
        '--cover-image-height',
        coverImageBounding.height + 'px'
      )
    }

    const header = document.querySelector('header #nav-top')
    if (header) {
      const headerBounding = header.getBoundingClientRect()
      document.documentElement.style.setProperty(
        '--header-height',
        headerBounding.height + 'px'
      )
    }

    const footer = document.querySelector('footer')
    if (footer) {
      const footerBounding = footer.getBoundingClientRect()
      document.documentElement.style.setProperty(
        '--footer-height',
        footerBounding.height + 'px'
      )
    }
  }

  return (
    <WrapperContext.Provider
      value={{
        settings: pageContext,
        location,
        messageList,
        setMessageList,
        headlinePayload,
        setHeadlinePayload,
      }}
    >
      <div id="page">
        <Header />

        <PageTransition location={location}>
          <main>{children}</main>
        </PageTransition>
        <Footer />

        <ModalImage />
        <LoaderOverlay />
      </div>
    </WrapperContext.Provider>
  )
}

export { WrapperContext, Layout }
export default function useWrapperContext() {
  return useContext(WrapperContext)
}
