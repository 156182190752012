import React from 'react'
// import MenuItem from "./MenuItem"
import { Link } from 'gatsby'
import Button from './ui/buttons/Button'
import { _localizeField, _linkResolver, _localizeText } from '../core/utils'

type Props = {
  input: Queries.SanityExternalLinkOrInternalLink[]
  label: string
}
const Menu = ({ input, label }: Props) => (
  <>
    <div className="label mb-1">{label}</div>
    <ul id="menu" className="flex flex-wrap gap-05">
      {input.map((li, i) => (
        <li key={li._key} className="mb-1-">
          {li._type === 'internalLink' && (
            <Link
              to={_linkResolver(li.link)}
              className="btn btn--big block btn--shaded"
            >
              {_localizeField(li.label)}
            </Link>
          )}
          {li._type === 'externalLink' && (
            <a
              href={li.url}
              target="_blank"
              rel="noopener noreferrer"
              className="btn btn--big block"
            >
              {_localizeField(li.label)}
            </a>
          )}
          {li._type === 'download' && (
            <a
              href={li.file.asset.url}
              target="_blank"
              rel="noopener noreferrer"
              className="btn btn--big block"
            >
              {_localizeField(li.label)}
            </a>
          )}
        </li>
      ))}
    </ul>
  </>
)

export default Menu
