//const website = require('./website')

module.exports = {
  de: {
    default: true,
    label: 'Deutsch',
    labelShort: 'DE',
    path: 'de',
    locale: 'de',
    siteLanguage: 'de',
    ogLang: 'de-DE',
    contact: 'Kontakt',
    language: 'Sprache',
    media: 'Medien',
    location: 'Ort', //location label
    locations: 'Orte', //locations label
    serie: 'Serie', //series label in festival
    series: 'Serien', //series label in festival
    filterSeries: 'Filter Serien',
    people: 'Profile', //if people group label empty
    profile: 'Profile',
    phase: 'Phase', //phase label
    productions: 'Produktionen', //productions label
    production: 'Produktion',
    loading: 'Suche läuft', //slideshow
    cookieButtonText: 'OK',
    search: 'Suche',
    searchResult: 'Ergebnis',
    searchResults: 'Ergebnisse',
    noResults: 'keine Ergebnisse',
    navigation: 'Navigation',
    hour: 'Uhr',
    menu: 'Menü',
    dates: 'Termine',
    pagetype: 'Seitentyp',
    program: 'Programm',
    links: 'Links',
    title: 'Titel',
    tags: 'Tags',
    festival: 'Festival',
    feedLinks: 'Verwandte Artikel',
    files: 'Downloads',
    social: 'Social',
  },

  en: {
    default: false,
    label: 'English',
    labelShort: 'EN',
    path: 'en',
    locale: 'en',
    siteLanguage: 'en',
    ogLang: 'en-GB',
    contact: 'Contact',
    language: 'Language',
    media: 'Media',
    location: 'Location', //location label
    locations: 'Locations', //locations label
    serie: 'Series', //series label in festival
    series: 'Series', //series label in festival
    filterSeries: 'Filter Series',
    people: 'Profiles', //if people group label empty
    profile: 'Profiles',
    festival: 'Festival', //festival label
    phase: 'Phase', //phase label
    productions: 'Productions', //productions label
    production: 'Production',
    loading: 'Searching', //slideshow
    cookieButtonText: 'OK',
    search: 'Search',
    searchResult: 'Result',
    searchResults: 'Results',
    noResults: 'No results',
    navigation: 'Navigation',
    hour: 'hour',
    menu: 'Menu',
    dates: 'Dates',
    pagetype: 'page type',
    program: 'Program',
    links: 'Links',
    title: 'Title',
    tags: 'Tags',
    feedLinks: 'Related Articles',
    files: 'Downloads',
    social: 'Social',
  },
}
