import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import { publish, subscribe, unsubscribe } from 'pubsub-js'
import SanityImage from '../SanityImage'
import styled from 'styled-components'
// import Plus from '../../images/plus.inline.svg'
import ButtonClose from './buttons/ButtonClose'
// import { _localizeText } from '../../core/utils'
import ButtonContext from './buttons/ButtonContext'

const Container = styled.div`
  height: 100vh;
  /* overflow-y: auto; */
  // display: flex;
  // justify-content: center;
  // align-items: center;
  .body > div {
    height: 100%;
  }
  figure {
    height: 100vh;
  }
  .gatsby-image-wrapper,
  img {
    width: 100%;
    height: 100%;
  }
  .loader {
    animation: blink-animation 1s steps(5, start) infinite;
  }
  .figcaption--list {
    & > li {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      &:first-child {
        flex: 0 1 auto;
        max-width: calc(var(--ui-max-width) + 3rem);
      }
    }
  }
`
/*
each thumb has an index set here
on thumb click we set the index state var
on arrow prev/next click we go to prev next index, and trigger click
*/

// let _lastScrollTop

const ModalImage = () => {
  const [expand, setExpand] = useState()
  const [image, setImage] = useState()
  const [index, setIndex] = useState()
  const [metadata, setMetadata] = useState()

  useEffect(() => {
    // set index for all images
    // const thumbnails = document.querySelectorAll('.thumbnail')
    // thumbnails.forEach((el, i) => {
    //   el.dataset.index = i
    // })
    // _addIndexToAllImages()
    // const tokenRoute = subscribe('ROUTE_UPDATE', _addIndexToAllImages)

    const tokenModal = subscribe('IMAGE_MODAL', (e, payload) => {
      // _lastScrollTop = window.pageYOffset || document.documentElement.scrollTop
      console.log(payload)

      setImage(payload.data)
      setIndex(parseFloat(payload.index))
      if (payload.metadata) setMetadata(payload.metadata)
      _open()
    })

    return () => {
      // unsubscribe(tokenRoute)
      unsubscribe(tokenModal)
    }
  }, [])

  useEffect(() => {
    if (expand) {
      document.addEventListener('keyup', _onKeyDown)
    } else {
      document.removeEventListener('keyup', _onKeyDown)
    }
    return () => document.removeEventListener('keyup', _onKeyDown)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expand, index])

  useEffect(() => {
    // console.log(index)
    const thumbnail = document.querySelector(
      `.thumbnail[data-index='${index}']:not(.is-active)`
    )
    if (thumbnail) {
      // console.log(thumbnail)
      thumbnail.click()
    } else {
      _close()
    }
  }, [index])

  const _onKeyDown = e => {
    // console.log(e.key)
    switch (e.key) {
      case 'Escape':
        _close()
        break
      case 'ArrowRight':
        _next()
        break
      case 'ArrowLeft':
        _prev()
        break
      default:
        break
    }
  }

  const _open = () => {
    setExpand(true)
  }

  const _next = () => {
    publish('IS_LOADING', true)
    setIndex(index + 1)
  }

  const _prev = () => {
    publish('IS_LOADING', true)
    setIndex(index - 1)
  }

  const _close = () => {
    setExpand(false)
    // setImage(null)
    // publish('IS_MODAL', false)
  }

  const _onLoad = () => {
    publish('IS_LOADING', false)
  }
  // console.log(image)
  return (
    <div
      className={clsx(
        'modal fixed  left-0 top-0 w-full h-screen z-50 bg-black pointer-events-auto ',
        !expand ? 'hidden' : ''
      )}
      // role="button"
      // tabIndex={0}
      // onKeyDown={() => setExpand(false)}
      // onClick={() => setExpand(false)}
    >
      <Container>
        <div className="h-screen">
          {/* <div className="loader center-x-y text-white text-lg">
            {_localizeText('loading...')}
          </div> */}

          {image && (
            <div className="h-full body">
              <SanityImage
                image={image}
                alt=""
                objectFit="contain"
                _onLoad={_onLoad}
              />
            </div>
          )}
          <div
            className="close-wrapper absolute left-1 top-1 z-10 p-sm"
            tabIndex={-1}
            role="button"
            onKeyDown={() => setExpand(false)}
            onClick={() => setExpand(false)}
          >
            <ButtonClose setActive={setExpand} />
          </div>
          <button
            className="chevron chevron--prev absolute left-1 top-1/2 -translate-y-full "
            onClick={_prev}
          >
            prev
          </button>
          <button
            className="chevron chevron--next absolute right-1 top-1/2"
            onClick={_next}
          >
            next
          </button>
          {metadata && (
            <figcaption className="footer absolute bottom-0 p-1 w-full pointer-events-none ">
              <ul className="figcaption--list flex flex-col-reverse md:flex-row">
                <li className="mr-1">
                  <ButtonContext size="regular" dark={true}>
                    {metadata.title}
                  </ButtonContext>
                </li>
                {metadata.festivals && (
                  <li className="mr-1">
                    <ul className="festivals flex ">
                      {metadata.festivals &&
                        metadata.festivals.map((item, i) => (
                          <li key={i}>
                            <div
                              className={clsx(
                                'btn btn--regular btn--rounded btn--dark mr-05-  mb-1 md:mb-0 text-center',
                                i < metadata.festivals.length - 1 ? 'mr-05' : ''
                              )}
                            >
                              {item}
                            </div>
                          </li>
                        ))}
                    </ul>
                  </li>
                )}

                {image.asset?.caption && (
                  <li>
                    <ButtonContext size="regular" dark={true}>
                      {image.asset?.caption}
                    </ButtonContext>
                  </li>
                )}
              </ul>
            </figcaption>
          )}
        </div>
      </Container>
    </div>
  )
}

export default ModalImage
