import React, { useEffect, useRef, useState } from 'react'
import { publish, subscribe, unsubscribe } from 'pubsub-js'
import SanityImage from '../../SanityImage'
import clsx from 'clsx'

const Thumbnail = ({ input, size = 60, metadata = {} }) => {
  // console.log(input)
  const [active, setActive] = useState()
  const ref = useRef()

  useEffect(() => {
    const tokenModal = subscribe('IS_MODAL', (e, is) => {
      if (!is) {
        setActive(false)
      }
    })

    return () => unsubscribe(tokenModal)
  }, [])

  useEffect(() => {
    if (active) {
      console.log(ref.current)
      publish('IMAGE_MODAL', {
        index: ref.current.dataset.index,
        data: input,
        metadata: metadata,
      })

      //reset if trigger clicked from modal image
      setTimeout(() => {
        setActive(false)
      }, 150)
    }
  }, [active, input, metadata])

  return (
    <div
      className={clsx(
        'thumbnail thumbnail--rounded cursor-pointer'
        // active ? 'is-active' : ''
      )}
      ref={ref}
      onClick={() => setActive(true)}
      onKeyUp={() => setActive(true)}
      role="button"
      tabIndex={-1}
    >
      {input?.asset && (
        <SanityImage image={input} thumbnail={true} height={size} />
        // <img src="https://picsum.photos/200" alt="" />
        // <img src={input.asset.url} alt="" />
      )}
    </div>
  )
}

export default Thumbnail
