exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-archive-tsx": () => import("./../../../src/templates/Archive.tsx" /* webpackChunkName: "component---src-templates-archive-tsx" */),
  "component---src-templates-basic-article-tsx": () => import("./../../../src/templates/BasicArticle.tsx" /* webpackChunkName: "component---src-templates-basic-article-tsx" */),
  "component---src-templates-basic-directory-tsx": () => import("./../../../src/templates/BasicDirectory.tsx" /* webpackChunkName: "component---src-templates-basic-directory-tsx" */),
  "component---src-templates-basic-feed-tsx": () => import("./../../../src/templates/BasicFeed.tsx" /* webpackChunkName: "component---src-templates-basic-feed-tsx" */),
  "component---src-templates-basic-program-tsx": () => import("./../../../src/templates/BasicProgram.tsx" /* webpackChunkName: "component---src-templates-basic-program-tsx" */),
  "component---src-templates-location-tsx": () => import("./../../../src/templates/Location.tsx" /* webpackChunkName: "component---src-templates-location-tsx" */),
  "component---src-templates-people-tsx": () => import("./../../../src/templates/People.tsx" /* webpackChunkName: "component---src-templates-people-tsx" */),
  "component---src-templates-production-tsx": () => import("./../../../src/templates/Production.tsx" /* webpackChunkName: "component---src-templates-production-tsx" */),
  "component---src-templates-serie-tsx": () => import("./../../../src/templates/Serie.tsx" /* webpackChunkName: "component---src-templates-serie-tsx" */)
}

