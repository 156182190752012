import React, { useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import HeaderNav from './HeaderNav'
import useWrapperContext from './Layout'
import UiMarquee from './ui/UiMarquee'
import { _localizeField } from '../core/utils'
// import SanityImage from './SanityImage'
// import Headline from './Headline'
// import { getCookieConsentValue } from 'react-cookie-consent'
// import CardContext from './ui/cards/CardContext'
// import CookieConsent from './ui/CookieConsent'
// import { subscribe, unsubscribe } from 'pubsub-js'
// import CardExpandable from './ui/cards/CardExpandable'
// import clsx from 'clsx'

const query = graphql`
  query {
    sanityHeader {
      topNavLink {
        externalLink: _rawExternalLink
        intternalLink: _rawInternalLink
      }
      navMenu {
        ... on SanityInternalLink {
          _key
          _type
          label {
            ...localeString
          }
          link {
            ... on SanityBasic {
              _type
              slug {
                current
              }
              landingPage
            }
          }
        }
        ... on SanityExternalLink {
          _key
          _type
          label {
            ...localeString
          }
          url: link
        }
        ... on SanityDownload {
          _key
          _type
          label {
            ...localeString
          }
          file {
            asset {
              url
            }
          }
        }
      }
      navMenuSecondary {
        ... on SanityInternalLink {
          _key
          _type
          label {
            ...localeString
          }
          link {
            ... on SanityBasic {
              _type
              slug {
                current
              }
              landingPage
            }
            ... on SanitySerie {
              _type
              slug {
                current
              }
            }
          }
        }
        ... on SanityExternalLink {
          _key
          _type
          label {
            ...localeString
          }
          url: link
        }
      }
    }
  }
`

const Header = () => {
  const {
    sanityHeader: { topNavLink, navMenu, navMenuSecondary },
  } = useStaticQuery(query)

  const {
    settings: { cover, template, h1, headlineType },
  } = useWrapperContext()

  const headlineLocalized = _localizeField(h1)

  return (
    <>
      {headlineType === 'marquee' && (
        <div className="marquee-wrapper pt-1 px-1">
          <UiMarquee headline={headlineLocalized} />
        </div>
      )}

      <HeaderNav
        topNavLink={topNavLink}
        navMenu={navMenu}
        navMenuSecondary={navMenuSecondary}
      />
    </>
  )
}

export default Header
