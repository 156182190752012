import locales from '../../config/i18n'
import useLocale from '../contexts/LocaleWrapper'

export function fileNameByUrl(url) {
  const decoded = decodeURIComponent(url)
  return decoded.substring(decoded.lastIndexOf('/') + 1)
}

/**
 * Shuffles array in place. ES6 version
 * @param {Array} a items An array containing the items.
 */
export function shuffle(a) {
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    ;[a[i], a[j]] = [a[j], a[i]]
  }
  return a
}

export function _linkResolver(doc) {
  if (!doc || !doc.slug) return '/'
  // console.log(doc)
  if (doc.landingPage) return '/'

  switch (doc._type) {
    case 'event':
      return `/event/${doc.slug.current}`

    case 'tag':
      return `/tag/${doc.slug.current}`

    case 'production':
      return `/produktion/${doc.slug.current}`

    case 'serie':
      return `/serie/${doc.slug.current}`

    case 'people':
      return `/people/${doc.slug.current}`

    case 'location':
      return `/location/${doc.slug.current}`

    case 'directory':
      return `/directory/${doc.slug.current}`

    default:
      return `/${doc.slug.current}`
  }
}

export const _localizeText = text => {
  // const locale = "fr"
  const { locale } = useLocale()

  return locales[locale] && locales[locale][text] ? locales[locale][text] : text
}

export const _localizeField = field => {
  const { locale } = useLocale()
  // console.log(locale)
  if (!field) return ''
  return field && field[locale] ? field[locale] : field['de']
}

export const _excerptify = text => {
  if (!text) return ''
  let output = text[0].children[0].text
  if (output.length > 10) output = output.substring(0, 100) + '...'

  return output
}

export const animateNumber = (start, end, duration) => {
  let startTimestamp = null
  const step = timestamp => {
    if (!startTimestamp) startTimestamp = timestamp
    const progress = Math.min((timestamp - startTimestamp) / duration, 1)

    if (progress < 1) {
      window.requestAnimationFrame(step)
    }
  }
  window.requestAnimationFrame(step)
}

// inspired by https://github.com/GoogleChrome/workbox/blob/3d02230f0e977eb1dc86c48f16ea4bcefdae12af/packages/workbox-core/src/_private/logger.ts

const styles = [
  `background: rebeccapurple`,
  `border-radius: 0.5em`,
  `color: white`,
  `font-weight: bold`,
  `padding: 2px 0.5em`,
].join(`;`)

export function debugLog(...args) {
  console.debug(`%cgatsby`, styles, ...args)
}

export const _getColorByEventType = evt => {
  switch (evt) {
    case 'exposition':
      return 'text-rouge'

    case 'mediatheque':
      return 'text-rose'

    case 'radio':
      return 'text-bleue'

    case 'workshop':
      return 'text-vert'

    case 'projection':
      return 'text-marron'

    case 'concert':
      return 'text-jaune'
    default:
      return 'text-primary'
  }
}

export const _getBackgroundColorByEventType = evt => {
  switch (evt) {
    case 'exposition':
      return 'rouge'

    case 'mediatheque':
      return 'rose'

    case 'radio':
      return 'bleue'

    case 'workshop':
      return 'vert'

    case 'projection':
      return 'marron'

    case 'concert':
      return 'jaune'
    default:
      return 'primary'
  }
}

export const _pad2 = n => {
  return (n < 10 ? '0' : '') + n
}

export const getCookie = cname => {
  let name = cname + '='
  let decodedCookie = decodeURIComponent(document.cookie)
  let ca = decodedCookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ''
}

export const get_youtube_video_id_by_url = url => {
  //eslint-disable-next-line no-useless-escape
  // const isNo
  const youtubeIdRegex =
    /^(?:(?:https|http):\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be).*?(?:\/|v\/|u\/|embed\/|shorts\/|watch\?v=|(?<username>user\/))(?<id>[\w\-]{11})(?:\?|&|$)/
  // console.log(url.match(youtubeIdRegex))
  const match = url.match(youtubeIdRegex)
  if (match?.groups?.id) {
    return match?.groups?.id
  } else {
    return null
  }
  // const reg =
  //   /^(https?:)?(\/\/)?((www\.|m\.)?youtube(-nocookie)?\.com\/((watch)?\?(feature=\w*&)?vi?=|embed\/|vi?\/|e\/)|youtu.be\/)([\w\-]{10,20})/i
  // let match = url.match(reg)
  // if (match) {
  //   return match[9]
  // } else {
  //   let regex =
  //     /(youtu.*be.*)\/(watch\?v=|embed\/|v|shorts|)(.*?((?=[&#?])|$))/gm
  //   match = url.match(regex)
  //   // console.log(match)
  //   if (match) {
  //     return match
  //   } else {
  //     return null
  //   }
  // }
}

export const get_youtube_video_thumbnail_by_url = url => {
  // console.log(url);
  let ID = get_youtube_video_id_by_url(url)

  return `https://i.ytimg.com/vi/${ID}/hqdefault.jpg`
}

export const _getContrast = hexcolor => {
  // If a leading # is provided, remove it
  if (hexcolor.slice(0, 1) === '#') {
    hexcolor = hexcolor.slice(1)
  }

  // Convert to RGB value
  var r = parseInt(hexcolor.substr(0, 2), 16)
  var g = parseInt(hexcolor.substr(2, 2), 16)
  var b = parseInt(hexcolor.substr(4, 2), 16)

  // Get YIQ ratio
  var yiq = (r * 299 + g * 587 + b * 114) / 1000

  // Check contrast
  return yiq >= 128 ? 'black' : 'white'
}

export const _getDMY = raw => {
  const date = new Date(raw)
  const day = date.toLocaleDateString('en', {
    day: '2-digit',
  })
  const month = date.toLocaleDateString('en', {
    month: '2-digit',
  })
  const year = date.getFullYear()
  return `${year}/${month}/${day}`
}

export const _getDMYAlt = raw => {
  const date = new Date(raw)
  const day = date.toLocaleDateString('en', {
    day: '2-digit',
  })
  const month = date.toLocaleDateString('en', {
    month: '2-digit',
  })

  const year = date.getFullYear()
  return `${day}.${month}.${year}`
}

export const _getParsedDate = raw => {
  const { locale } = useLocale()
  const { ogLang } = locales[locale]
  // console.log(ogLang)
  const date = new Date(raw)
  const time =
    ogLang === 'en-GB'
      ? `${_formatAMPM(date)} `
      : `${(date.getHours() < 10 ? '' : '') + date.getHours()}.${
          (date.getMinutes() < 10 ? '0' : '') + date.getMinutes()
        }`
  return {
    weekday: date.toLocaleDateString(ogLang, { weekday: 'short' }),
    day: date.toLocaleDateString(ogLang, { day: '2-digit' }),
    month: date.toLocaleDateString(ogLang, { month: '2-digit' }),
    year: date.getFullYear(),
    hours: date.getHours(),
    minutes: date.getMinutes(),
    dayMonthYear: `${date.toLocaleDateString(ogLang, {
      day: '2-digit',
    })}-${date.toLocaleDateString(ogLang, {
      month: '2-digit',
    })}-${date.getFullYear()}`,
    dayMonthYearAlt: `${date.toLocaleDateString(ogLang, {
      day: 'numeric',
    })}.${date.toLocaleDateString(ogLang, {
      month: 'numeric',
    })}.${date.getFullYear()}`,
    time: time,
    full: `${date.toLocaleDateString(ogLang, {
      day: 'numeric',
    })}.${date.toLocaleDateString(ogLang, {
      month: 'numeric',
    })}.${date.getFullYear()},
    ${time}`,
  }
}

export const _isSameDay = (d1: Date, d2: Date) => {
  return (
    d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate()
  )
}

function _formatAMPM(date) {
  var hours = date.getHours()
  var minutes = date.getMinutes()
  var ampm = hours >= 12 ? 'pm' : 'am'
  hours = hours % 12
  hours = hours ? (hours < 10 ? '0' + hours : hours) : 12 // the hour '0' should be '12'
  // console.log(hours)
  minutes = minutes < 10 ? '0' + minutes : minutes
  var strTime = hours + ':' + minutes + ' ' + ampm.toUpperCase()
  return strTime
}

export const _randInArray = arr => {
  const randIndex = Math.ceil(Math.random() * arr.length - 1)
  // console.log({ randIndex }, arr.length)

  arr[randIndex].graphic = true
  return arr[randIndex]
}

export const _string_to_slug = (str: string) => {
  // console.log('_string_to_slug')
  // console.log(str)
  // return ''
  if (!str) return ''
  str = str.replace(/^\s+|\s+$/g, '') // trim
  str = str.toLowerCase()

  // remove accents, swap ñ for n, etc
  var from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;'
  var to = 'aaaaeeeeiiiioooouuuunc------'
  for (var i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
  }

  str = str
    .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
    .replace(/\s+/g, '-') // collapse whitespace and replace by -
    .replace(/-+/g, '-') // collapse dashes

  return str
}

export const _stripAccents = input => {
  var translate_re = /[àáâãäçèéêëìíîïñòóôõöùúûüýÿÀÁÂÃÄÇÈÉÊËÌÍÎÏÑÒÓÔÕÖÙÚÛÜÝ]/g
  var translate = 'aaaaaceeeeiiiinooooouuuuyyAAAAACEEEEIIIINOOOOOUUUUY'
  return input.replace(translate_re, function (match) {
    return translate.substr(translate_re.source.indexOf(match) - 1, 1)
  })
}

type CurrentFestProp = Queries.SanityProduction
export const _productionIsInCurrentFestival = (input: CurrentFestProp) => {
  if (!input.festivals) return false
  const _festivals = input.festivals.filter(
    el => el.year === process.env.GATSBY_CURRENT_FESTIVAL
  )
  return _festivals.length > 0
}
